<template>
  <div class="flex flex-column w-screen h-screen">
    <div class="flex-auto flex flex-column gap-5 justify-content-center align-items-center p-6 pt-0 text-center">
      <div ref="lottie" style="width: 300px; aspect-ratio: 1; clip-path: polygon(0 0, 95% 0, 96% 100%, 0% 100%);"></div>
      <p class="text-3xl font-bold">Whoops, diese Biene arbeitet noch an der Seite.</p>
      <p>Schaut doch später nochmal vorbei. 😊</p>
    </div>
    <div class="w-full pb-3 px-6">
      <p class="text-sm text-center text-gray-500">Imkerei Pächnatz • <span class="font-bold">E-Mail</span> yvonne.paechnatz@gmx.de • <span class="font-bold">Tel.</span> 0174 / 3261438</p>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';

export default {
  name: 'App',
  data() {
    return {
    }
  },
  components: {
  },
  methods: {
    initLottie() {
      lottie.loadAnimation({
        container: this.$refs.lottie,
        path: 'assets/bee.json',
        renderer: 'canvas',
        loop: true,
        autoplay: true,
        name: "bee-1",
      });
    }
  },
  mounted() {
    this.initLottie();
  }
}
</script>

<style></style>
